import React, { useState, useMemo, useEffect } from 'react';
import { Container, Row, Col, Table, Button, Input } from 'reactstrap';
import Helper from '@helpers/Helper';
import '../../styles/compare_price_content.scss';
import IconCopy from '@assets/icons/copy.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import EstimationPriceCompareWorkshop from '../../helpers/text/EstimationPriceCompareWorkshop';
import OverridePriceModal from '../modal/OverridePriceModal';
import IconDownload from '@assets/icons/download.svg';
import OfferingLetterPdf from '../../helpers/pdf/OfferingLetterPdf';
import _ from 'lodash';
import CustomModal from '@components/modal/CustomModal';
import overrideIcon from '@assets/icons/override-price.svg';

const ComparePriceContent = (props) => {
  const {
    isUnlockOverrideField,
    multipleCalculateCartData,
    selectedWorkshops,
    tempBookingData,
    handlePriceChange,
    overrideList,
    b2bDraft,
    handleQtyChange,
    setWiProductObj,
    onSubmit,
  } = props;
  const [prices, setPrices] = useState({});
  const [isPKPModalOpen, setIsPKPModalOpen] = useState(false);
  const [currentNettPrice, setCurrentNettPrice] = useState(0);
  const [selectedCartItem, setSelectedCartItem] = useState();
  const [isDownloadOfferingLetterModalOpen, setIsDownloadOfferingLetterModalOpen] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [nettPriceInputValues, setNettPriceInputValues] = useState({});

  const handlePriceReset = (
    workshopId,
    productId,
    price = null,
    nettPrice = null,
    originalPrice = null,
    originalNettPrice = null,
    splitTax = false,
    updateFor = 'nettPrice'
  ) => {
    if (updateFor === 'price') {
      setInputValues((prevState) => ({
        ...prevState,
        [workshopId]: {
          ...prevState[workshopId],
          [productId]: originalPrice === -1 ? '' : `Rp. ${new Intl.NumberFormat('id-ID').format(originalPrice)}`,
        },
      }));
    } else {
      setNettPriceInputValues((prevState) => ({
        ...prevState,
        [workshopId]: {
          ...prevState[workshopId],
          [productId]:
            originalNettPrice === -1 ? '' : `Rp. ${new Intl.NumberFormat('id-ID').format(originalNettPrice)}`,
        },
      }));
    }
    handlePriceChange(workshopId, productId, price, nettPrice, originalPrice, originalNettPrice, splitTax, updateFor);
  };

  const handlePKPModalOpen = (workshop_id, matchingGroup, nettPriceValue, workshopIsPKP) => {
    if (workshopIsPKP) {
      setSelectedCartItem({ ...matchingGroup, workshop_id: workshop_id, nettPriceValue: nettPriceValue }); // Only set this when opening the modal
    }

    setIsPKPModalOpen(workshopIsPKP); // Toggle modal state
  };

  const downloadEstimatedPrice = (workshopId) => {
    if (b2bDraft !== undefined && b2bDraft !== null) {
      let selectedWorkshopDataCart = multipleCalculateCartData.find((item) => item.workshop_id === workshopId);
      const summary_offering_letter = _.cloneDeep(selectedWorkshopDataCart.summary_offering_letter);

      OfferingLetterPdf.create(b2bDraft, summary_offering_letter);
    } else {
      setIsDownloadOfferingLetterModalOpen(true);
    }
  };

  const handleCopyClipBoard = (workshopId) => {
    const workshop = selectedWorkshops.find((ws) => ws.id == workshopId);
    const estimationText = EstimationPriceCompareWorkshop.create(
      { ...tempBookingData, workshop: workshop },
      multipleCalculateCartData,
      workshopId
    );

    return estimationText;
  };

  const handleCopyClipBoardWorkshop = (workshopId) => {
    const workshop = selectedWorkshops.find((ws) => ws.id == workshopId);
    const estimationText = EstimationPriceCompareWorkshop.createWorkshopPrice(
      { ...tempBookingData, workshop: workshop },
      multipleCalculateCartData,
      workshopId
    );

    return estimationText;
  };
  
  const groupedData = useMemo(() => {
    return multipleCalculateCartData?.reduce((acc, item) => {
      const { workshop_id, workshop_name } = item;

      acc[workshop_id] = acc[workshop_id] || {
        workshop_name,
        workshop_id,
        is_pkp: selectedWorkshops.find((ws) => ws.id == workshop_id)?.is_pkp ?? false,
        items: [],
        // Customer Data
        customer_tax: item.customer.tax,
        customer_dpp: item.customer.price_before_tax,
        customer_pph: item.customer.service_tax,
        customer_total: item.customer.total_amount,
        // Workshop Data
        workshop_tax: item.workshop.tax,
        workshop_dpp: item.workshop.price_before_tax,
        workshop_pph: item.workshop.service_tax,
        workshop_total: item.workshop.total_amount,
      };

      const addItem = (source, targetKey) => {
        if (source.items) {
          source.items.forEach((sourceItem) => {
            if (sourceItem?.product_id !== 'mock_item') {
              const groupKey = sourceItem.package_id ? sourceItem.package_name : sourceItem.name;
              let existingGroup = acc[workshop_id].items.find((i) => i.groupKey === groupKey);

              if (!existingGroup) {
                existingGroup = {
                  groupKey,
                  package_name: sourceItem.package_id ? sourceItem.package_name : null,
                  package_id: sourceItem.package_id ?? null,
                  product_id: sourceItem.product_id ?? null,
                  items: [],
                  customer_final_sell_price: 0, // Ensure this is initialized
                  workshop_final_sell_price: 0, // Ensure this is initialized
                  isWi: sourceItem?.is_wi,
                  isPackage: 'package_id' in sourceItem,
                  isCustom: sourceItem?.is_custom,
                  isOverrideNett: sourceItem?.is_override_nett ?? false,
                  isOverrideSell: sourceItem?.is_override_sell ?? false,
                  price: sourceItem.price,
                  originalPrice: sourceItem.original_price,
                  nettPrice: sourceItem.nett_price,
                  originalNettPrice: sourceItem.original_nett_price,
                  remarks: sourceItem.note,
                  isSplitedTax: sourceItem?.is_splited_tax ?? false,
                  qty: sourceItem.qty,
                };
                acc[workshop_id].items.push(existingGroup);
              }

              setWiProductObj((prev) => ({
                ...prev,
                [workshop_id]: {
                  ...(prev[workshop_id] || {}),
                  [sourceItem.product_id]: {
                    name: sourceItem.name,
                    is_wi: sourceItem.is_wi,
                    price: sourceItem.price,
                    original_price: sourceItem.original_price,
                    final_sell_price: sourceItem.final_sell_price,
                    total_price: sourceItem.total_price,
                    nett_price: sourceItem.nett_price,
                    original_nett_price: sourceItem.original_nett_price,
                    isOverrideNett: sourceItem?.is_override_nett ?? false,
                    isOverrideSell: sourceItem?.is_override_sell ?? false,
                    isSplitedTax: sourceItem?.is_splited_tax ?? false,
                    remarks: sourceItem?.note ?? '',
                  },
                },
              }));

              if (targetKey === 'customer_final_sell_price') {
                existingGroup.customer_final_sell_price =
                  (existingGroup.customer_final_sell_price || 0) + (sourceItem.final_sell_price || 0);
              } else if (targetKey === 'workshop_final_sell_price') {
                existingGroup.workshop_final_sell_price =
                  (existingGroup.workshop_final_sell_price || 0) + (sourceItem.total_workshop_price || 0);
              }

              const itemIndex = existingGroup.items.findIndex((i) => i.name === sourceItem.name);
              if (itemIndex !== -1) {
                existingGroup.items[itemIndex][targetKey + 'Item'] = sourceItem.final_sell_price;
              } else {
                existingGroup.items.push({
                  name: sourceItem.name,
                  [targetKey + 'Item']: sourceItem.final_sell_price,
                });
              }
            }
          });
        }
      };

      addItem(item.customer, 'customer_final_sell_price');
      addItem(item.workshop, 'workshop_final_sell_price');

      return acc;
    }, {});
  }, [multipleCalculateCartData]);

  const shouldDisableButton = (workshopId) => {
    const workshop = groupedData[workshopId];
    if (!workshop) return false;

    if (workshop.items.length == 0) {
      return true;
    } else {
      return false;
    }
  };

  const shouldDisableCopyButton = (workshopId) => {
    const workshop = groupedData[workshopId];
    const isDisable = workshop.items.some(
      (item) => !item.isWi && !item.isOverrideSell && item.package_name == null && !item.isCustom
    );
    return isDisable;
  };

  const handleCustomerPriceChange = (
    workshopId,
    productId,
    price = null,
    nettPrice = null,
    originalPrice = null,
    originalNettPrice = null,
    splitTax = false,
    updateFor = 'nettPrice'
  ) => {
    if (updateFor === 'price') {
      setInputValues((prevState) => ({
        ...prevState,
        [workshopId]: {
          ...prevState[workshopId],
          [productId]: price ? `Rp. ${new Intl.NumberFormat('id-ID').format(price)}` : '',
        },
      }));
    } else {
      if (typeof nettPrice === 'string') {
        setNettPriceInputValues((prevState) => ({
          ...prevState,
          [workshopId]: {
            ...prevState[workshopId],
            [productId]: nettPrice != '' ? `Rp. ${new Intl.NumberFormat('id-ID').format(nettPrice)}` : '',
          },
        }));
      } else {
        setNettPriceInputValues((prevState) => ({
          ...prevState,
          [workshopId]: {
            ...prevState[workshopId],
            [productId]: nettPrice?.value
              ? `Rp. ${new Intl.NumberFormat('id-ID').format(nettPrice?.value)}`
              : nettPrice?.value === 0
              ? `Rp. ${new Intl.NumberFormat('id-ID').format(nettPrice?.value)}`
              : '',
          },
        }));
      }
    }

    handlePriceChange(workshopId, productId, price, nettPrice, originalPrice, originalNettPrice, splitTax, updateFor);
  };

  const allUniqueItems = Object.values(groupedData)
    .flatMap((workshop) => workshop.items)
    .reduce((uniqueItems, item) => {
      if (!uniqueItems.find((ui) => ui.groupKey === item.groupKey)) {
        uniqueItems.push(item);
      }
      return uniqueItems;
    }, []);


  return (
    <Container fluid className="p-2">
      <Row>
        <Col>
          <span className="font-weight-bold h5">Harga Ke Customer</span>
        </Col>
      </Row>
      <div className="table-responsive">
        <Table bordered className="mt-2 equal-width-columns">
          <thead>
            <tr>
              <th className="bg-light">Paket/Item</th>
              <th className="bg-light text-center">Qty</th>

              {Object.values(groupedData).map((workshop) => (
                <th key={workshop.workshop_id} className="bg-light text-center">
                  {workshop.workshop_name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allUniqueItems.map((group) => (
              <React.Fragment key={group.groupKey}>
                <tr>
                  <td className="align-middle">
                    {group.package_name ? (
                      <>
                        <strong>{group.package_name}</strong>
                        <ul className="list-unstyled ms-3">
                          {group.items.map((item) => (
                            <li key={item.name}> - {item.name}</li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <strong>{group.groupKey}</strong>
                    )}
                  </td>
                  {!isUnlockOverrideField ? (
                    <td key={workshop.workshop_id} className="text-center">
                      {group.package_name ? '1' : group?.qty}
                    </td>
                  ) : (
                    <td key={workshop.workshop_id} className="text-center">
                      {group.package_name ? (
                        '1'
                      ) : (
                        <Input
                          defaultValue={group?.qty}
                          id="nett-price-edit"
                          className="input-field full-width text-center"
                          onChange={(e) => {
                            const rawValue = e.target.value.replace(/\D/g, '');

                            handleQtyChange(group?.product_id, rawValue);

                            e.target.value = rawValue;
                          }}
                        />
                      )}
                    </td>
                  )}
                  {Object.values(groupedData).map((workshop) => {
                    const matchingGroup = workshop.items.find((wg) => wg.groupKey === group.groupKey);
                    var finalPrice = matchingGroup ? Helper.formatMoney(matchingGroup.customer_final_sell_price) : '-';

                    finalPrice =
                      matchingGroup?.isWi ||
                      matchingGroup?.isPackage ||
                      matchingGroup?.isCustom ||
                      matchingGroup?.isOverrideSell
                        ? finalPrice
                        : '-';

                    const currentPrice = prices[workshop.workshop_id] || finalPrice;
                    const currentInputValue =
                      inputValues[workshop.workshop_id]?.[matchingGroup?.product_id] == ''
                        ? ''
                        : inputValues[workshop.workshop_id]?.[matchingGroup?.product_id] ||
                          (matchingGroup?.price
                            ? `Rp. ${new Intl.NumberFormat('id-ID').format(matchingGroup?.price)}`
                            : matchingGroup?.isWi || (!matchingGroup?.isWi && matchingGroup?.isOverrideSell)
                            ? 'Rp. 0'
                            : '');
                    const currentUpdatedPrice = matchingGroup?.isOverrideSell ? (
                      <>
                        <img
                          src={overrideIcon}
                          className="pr-2"
                          height={16}
                          alt="Close"
                          color="white"
                          style={{ cursor: 'pointer' }}
                        />
                        Rp. {currentPrice}
                      </>
                    ) : (
                      'Rp. ' + currentPrice
                    );

                    return (
                      <td key={workshop.workshop_id} className="text-right">
                        {!isUnlockOverrideField ? (
                          currentPrice !== '-' ? (
                            currentUpdatedPrice
                          ) : (
                            currentPrice
                          )
                        ) : matchingGroup?.package_id ? (
                          currentPrice !== '-' ? (
                            currentUpdatedPrice
                          ) : (
                            currentPrice
                          )
                        ) : matchingGroup === undefined ? (
                          currentPrice !== '-' ? (
                            currentUpdatedPrice
                          ) : (
                            currentPrice
                          )
                        ) : (
                          <>
                            <Row>
                              <Col lg={10} sm={12}>
                                <Input
                                  value={currentInputValue}
                                  id="nett-price-edit"
                                  className="input-field full-width"
                                  onChange={(e) => {
                                    const rawValue = e.target.value.replace(/\D/g, '');

                                    let formattedValue = '';

                                    if (rawValue) {
                                      formattedValue =
                                        'Rp. ' +
                                        new Intl.NumberFormat('id-ID', {
                                          style: 'decimal',
                                          maximumFractionDigits: 0,
                                        }).format(rawValue);
                                    } else {
                                      formattedValue = '';
                                    }

                                    handleCustomerPriceChange(
                                      workshop.workshop_id,
                                      matchingGroup?.product_id,
                                      rawValue,
                                      null,
                                      matchingGroup?.originalPrice,
                                      matchingGroup?.originalNettPrice,
                                      false,
                                      'price'
                                    );

                                    e.target.value = formattedValue;
                                  }}
                                />
                              </Col>
                              <Col lg={2}>
                                <Button
                                  id="override-sell-price-edit"
                                  color="primary"
                                  size="md"
                                  className={`justify-self-right ${
                                    matchingGroup?.isOverrideSell ? 'btn-override-price' : 'btn-no-override-price'
                                  }`}
                                  // disabled={disableAll || !selectedProduct}
                                  onClick={() =>
                                    handlePriceReset(
                                      workshop.workshop_id,
                                      matchingGroup?.product_id,
                                      currentInputValue,
                                      null,
                                      matchingGroup?.originalPrice,
                                      matchingGroup?.originalNettPrice,
                                      false,
                                      'price'
                                    )
                                  }
                                >
                                  <img src={overrideIcon} height={15} className="override-icon" />
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            ))}
            {!isUnlockOverrideField && (
              <>
                <tr>
                  <td className="bg-light fw-bold text-center" colSpan={2}>
                    DPP
                  </td>
                  {Object.values(groupedData).map((workshop) => (
                    <td key={workshop.workshop_id} className="text-right">
                      {'Rp. ' + Helper.formatMoney(workshop.customer_dpp ?? 0)}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="bg-light fw-bold text-center" colSpan={2}>
                    PPN
                  </td>
                  {Object.values(groupedData).map((workshop) => (
                    <td key={workshop.workshop_id} className="text-right">
                      {'Rp. ' + Helper.formatMoney(workshop.customer_tax ?? 0)}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="bg-light fw-bold text-center" colSpan={2}>
                    PPH
                  </td>
                  {Object.values(groupedData).map((workshop) => (
                    <td key={workshop.workshop_id} className="text-right" style={{ color: 'red' }}>
                      {'- Rp. ' + Helper.formatMoney(workshop.customer_pph ?? 0)}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="bg-light fw-bold text-center" colSpan={2}>
                    Total
                  </td>
                  {Object.values(groupedData).map((workshop) => (
                    <td key={workshop.workshop_id} className="text-right">
                      {'Rp. ' + Helper.formatMoney(workshop.customer_total ?? 0)}
                    </td>
                  ))}
                </tr>
              </>
            )}

            <tr>
              {!isUnlockOverrideField && (
                <>
                  <td colSpan={2}></td>
                  {Object.values(groupedData).map((workshop) => (
                    <td key={workshop.workshop_id} className="text-right">
                      <Row>
                        <CopyToClipboard
                          onCopy={() => {
                            handleCopyClipBoard(workshop.workshop_id);
                          }}
                          text={handleCopyClipBoard(workshop.workshop_id)}
                        >
                          <Button
                            // onClick={() => {
                            //   handleCopyClipBoard(workshop.workshop_id);
                            // }}
                            color="purple"
                            disabled={
                              shouldDisableButton(workshop.workshop_id) || shouldDisableCopyButton(workshop.workshop_id)
                            }
                            className="button-action save w-100 mb-2"
                          >
                            <img src={IconCopy} className="mr-2 mb-1" />
                            <span>Salin Estimasi Harga</span>
                          </Button>
                        </CopyToClipboard>
                        <Button
                          onClick={() => {
                            downloadEstimatedPrice(workshop.workshop_id);
                          }}
                          color="purple"
                          block
                          disabled={
                            shouldDisableButton(workshop.workshop_id) || shouldDisableCopyButton(workshop.workshop_id)
                          }
                          className="button-action save w-100"
                        >
                          <img src={IconDownload} className="mr-2 mb-1" />
                          <span>Unduh Surat Penawaran</span>
                        </Button>
                      </Row>
                    </td>
                  ))}
                </>
              )}
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th className="bg-light" colSpan={!isUnlockOverrideField ? 2 : 1}></th>
              {isUnlockOverrideField && <th className="bg-light text-center">Qty</th>}
              {Object.values(groupedData).map((workshop) => (
                <th key={workshop.workshop_id} className="bg-light text-center">
                  {workshop.workshop_name}
                </th>
              ))}
            </tr>
          </tfoot>
        </Table>
      </div>
      <Row className="mt-2">
        <Col>
          <span className="font-weight-bold h5">Harga Ke Bengkel</span>
        </Col>
      </Row>
      <div className="table-responsive">
        <Table bordered className="mt-2 equal-width-columns">
          <thead>
            <tr>
              <th className="bg-light">Paket/Item</th>
              <th className="bg-light text-center">Qty</th>
              {Object.values(groupedData).map((workshop) => (
                <th key={workshop.workshop_id} className="bg-light text-center">
                  {workshop.workshop_name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allUniqueItems.map((group) => (
              <React.Fragment key={group.groupKey}>
                <tr>
                  <td className="align-middle">
                    {group.package_name ? (
                      <>
                        <strong>{group.package_name}</strong>
                        <ul className="list-unstyled ms-3">
                          {group.items.map((item) => (
                            <li key={item.name}>- {item.name}</li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <strong className="mb-2">{group.groupKey}</strong>
                    )}
                    {/* <p>Keterangan: {group.remarks}</p> */}
                  </td>
                  {!isUnlockOverrideField ? (
                    <td key={workshop.workshop_id} className="text-center">
                      {group.package_name ? '1' : group?.qty}
                    </td>
                  ) : (
                    <td key={workshop.workshop_id} className="text-center">
                      {group.package_name ? (
                        '1'
                      ) : (
                        <Input
                          defaultValue={group?.qty}
                          id="nett-price-edit"
                          className="input-field full-width text-center"
                          onChange={(e) => {
                            const rawValue = e.target.value.replace(/\D/g, '');

                            handleQtyChange(group?.product_id, rawValue);

                            e.target.value = rawValue;
                          }}
                        />
                      )}
                    </td>
                  )}
                  {Object.values(groupedData).map((workshop) => {
                    const matchingGroup = workshop.items.find((wg) => wg.groupKey === group.groupKey);
                    var finalPrice = matchingGroup ? Helper.formatMoney(matchingGroup.workshop_final_sell_price) : '-';

                    finalPrice =
                      matchingGroup?.isWi ||
                      matchingGroup?.isPackage ||
                      matchingGroup?.isCustom ||
                      matchingGroup?.isOverrideNett
                        ? finalPrice
                        : '-';

                    const currentPrice = prices[workshop.workshop_id] || finalPrice;

                    const overrideObj = overrideList?.find(
                      (item) =>
                        item.workshop_id === workshop?.workshop_id && item?.product_id === matchingGroup?.product_id
                    );
                    // let overridePrice = matchingGroup?.nettPrice;
                    const currentInputValue =
                      nettPriceInputValues[workshop.workshop_id]?.[matchingGroup?.product_id] == ''
                        ? ''
                        : nettPriceInputValues[workshop.workshop_id]?.[matchingGroup?.product_id] ||
                          (matchingGroup?.nettPrice
                            ? `Rp. ${new Intl.NumberFormat('id-ID').format(matchingGroup?.nettPrice)}`
                            : matchingGroup?.isWi || (!matchingGroup?.isWi && matchingGroup?.is_override_nett)
                            ? 'Rp. 0'
                            : '');
                    const currentUpdatedPrice = matchingGroup?.isOverrideNett ? (
                      <>
                        <img
                          src={overrideIcon}
                          className="pr-2"
                          height={16}
                          alt="Close"
                          color="white"
                          style={{ cursor: 'pointer' }}
                        />
                        Rp. {currentPrice}
                      </>
                    ) : (
                      'Rp. ' + currentPrice
                    );

                    return (
                      <td key={workshop.workshop_id} className="text-right">
                        {!isUnlockOverrideField ? (
                          currentPrice !== '-' ? (
                            currentUpdatedPrice
                          ) : (
                            currentPrice
                          )
                        ) : matchingGroup?.isPackage ? (
                          currentPrice !== '-' ? (
                            currentUpdatedPrice
                          ) : (
                            currentPrice
                          )
                        ) : matchingGroup === undefined ? (
                          currentPrice !== '-' ? (
                            currentUpdatedPrice
                          ) : (
                            currentPrice
                          )
                        ) : matchingGroup?.isWi && currentPrice > 0 && !matchingGroup.isOverrideNett ? (
                          currentPrice !== '-' ? (
                            currentUpdatedPrice
                          ) : (
                            currentPrice
                          )
                        ) : workshop?.is_pkp ? (
                          <Row>
                            <Col lg={10} sm={12}>
                              <Input
                                id="nett-price-edit"
                                className="input-field full-width"
                                onClick={() =>
                                  handlePKPModalOpen(
                                    workshop.workshop_id,
                                    matchingGroup,
                                    parseInt(currentInputValue.replace(/[^\d]/g, ''), 10),
                                    workshop?.is_pkp
                                  )
                                }
                                value={currentInputValue}
                                onChange={(e) => {
                                  const rawValue = e.target.value.replace(/\D/g, '');

                                  const formattedValue =
                                    'Rp. ' +
                                    new Intl.NumberFormat('id-ID', {
                                      style: 'decimal',
                                      maximumFractionDigits: 0,
                                    }).format(rawValue);

                                  handleCustomerPriceChange(
                                    workshop.workshop_id,
                                    matchingGroup?.product_id,
                                    null,
                                    rawValue,
                                    null,
                                    matchingGroup?.originalNettPrice,
                                    false
                                  );
                                  e.target.value = formattedValue;
                                }}
                              />
                            </Col>
                            <Col lg={2}>
                              <Button
                                id="override-sell-price-edit"
                                color="primary"
                                size="md"
                                className={`justify-self-right ${
                                  matchingGroup?.isOverrideNett ? 'btn-override-price' : 'btn-no-override-price'
                                }`}
                                // disabled={disableAll || !selectedProduct}
                                onClick={() =>
                                  handlePriceReset(
                                    workshop.workshop_id,
                                    matchingGroup?.product_id,
                                    null,
                                    currentInputValue,
                                    matchingGroup?.originalPrice,
                                    matchingGroup?.originalNettPrice,
                                    false
                                  )
                                }
                              >
                                <img src={overrideIcon} height={15} className="override-icon" />
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col lg={10} sm={12}>
                              <Input
                                id="nett-price-edit"
                                className="input-field full-width"
                                // defaultValue={
                                //   matchingGroup?.nettPrice
                                //     ? 'Rp. ' + new Intl.NumberFormat('id-ID').format(matchingGroup?.nettPrice)
                                //     : ''
                                // }
                                value={currentInputValue}
                                onChange={(e) => {
                                  const rawValue = e.target.value.replace(/\D/g, '');

                                  let formattedValue = '';

                                  if (rawValue) {
                                    formattedValue =
                                      'Rp. ' +
                                      new Intl.NumberFormat('id-ID', {
                                        style: 'decimal',
                                        maximumFractionDigits: 0,
                                      }).format(rawValue);
                                  } else {
                                    formattedValue = '';
                                  }

                                  handleCustomerPriceChange(
                                    workshop.workshop_id,
                                    matchingGroup?.product_id,
                                    null,
                                    rawValue,
                                    null,
                                    matchingGroup?.originalNettPrice,
                                    false
                                  );
                                  e.target.value = formattedValue;
                                }}
                              />
                            </Col>
                            <Col lg={2}>
                              <Button
                                id="override-sell-price-edit"
                                color="primary"
                                size="md"
                                className={`justify-self-right ${
                                  matchingGroup?.isOverrideNett ? 'btn-override-price' : 'btn-no-override-price'
                                }`}
                                // disabled={disableAll || !selectedProduct}
                                onClick={() =>
                                  handlePriceReset(
                                    workshop.workshop_id,
                                    matchingGroup?.product_id,
                                    null,
                                    currentInputValue,
                                    matchingGroup?.originalPrice,
                                    matchingGroup?.originalNettPrice,
                                    false
                                  )
                                }
                              >
                                <img src={overrideIcon} height={15} className="override-icon" />
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            ))}
            {!isUnlockOverrideField && (
              <>
                <tr>
                  <td className="bg-light fw-bold text-center" colSpan={2}>
                    DPP
                  </td>
                  {Object.values(groupedData).map((workshop) => (
                    <td key={workshop.workshop_id} className="text-right">
                      {'Rp. ' + Helper.formatMoney(workshop.workshop_dpp ?? 0)}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="bg-light fw-bold text-center" colSpan={2}>
                    PPN
                  </td>
                  {Object.values(groupedData).map((workshop) => (
                    <td key={workshop.workshop_id} className="text-right">
                      {'Rp. ' + Helper.formatMoney(workshop.workshop_tax ?? 0)}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="bg-light fw-bold text-center" colSpan={2}>
                    Total
                  </td>
                  {Object.values(groupedData).map((workshop) => (
                    <td key={workshop.workshop_id} className="text-right">
                      {'Rp. ' + Helper.formatMoney(workshop.workshop_total ?? 0)}
                    </td>
                  ))}
                </tr>
                <tr>
                  {!isUnlockOverrideField && (
                    <>
                      <td colSpan={2}></td>
                      {Object.values(groupedData).map((workshop) => (
                        <td key={workshop.workshop_id} className="text-right">
                          <Row>
                            <CopyToClipboard
                              onCopy={() => handleCopyClipBoardWorkshop(workshop.workshop_id)}
                              text={handleCopyClipBoardWorkshop(workshop.workshop_id)}
                            >
                              <Button
                                color="purple"
                                disabled={shouldDisableButton(workshop.workshop_id)}
                                className="button-action save w-100 mb-2"
                              >
                                <img src={IconCopy} className="mr-2 mb-1" alt="copy icon" />
                                <span>Konfirmasi Harga Bengkel</span>
                              </Button>
                            </CopyToClipboard>
                            <Button
                              onClick={() => onSubmit(workshop.workshop_id, 'select-workshop')}
                              disabled={shouldDisableButton(workshop.workshop_id)}
                              className="button-action save w-100"
                            >
                              Pilih Bengkel
                            </Button>
                          </Row>
                        </td>
                      ))}
                    </>
                  )}
                </tr>
              </>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th className="bg-light" colSpan={!isUnlockOverrideField ? 2 : 1}></th>
              {isUnlockOverrideField && <th className="bg-light text-center">Qty</th>}
              {Object.values(groupedData).map((workshop) => (
                <th key={workshop.workshop_id} className="bg-light text-center">
                  {workshop.workshop_name}
                </th>
              ))}
            </tr>
          </tfoot>
        </Table>
      </div>
      {selectedCartItem && (
        <OverridePriceModal
          isOpen={isPKPModalOpen}
          toggle={handlePKPModalOpen}
          nettPrice={currentNettPrice}
          handleCustomerPriceChange={handleCustomerPriceChange}
          selectedCartItem={selectedCartItem}
        />
      )}
      <CustomModal modal={isDownloadOfferingLetterModalOpen} toggle={() => setIsDownloadOfferingLetterModalOpen(false)}>
        <div className="text-center">
          <p className="">Anda dapat mengunduh surat penawaran setelah menyimpan booking.</p>
          <Button
            color="primary"
            className="m-auto px-4 rounded-pill"
            size="lg"
            onClick={() => setIsDownloadOfferingLetterModalOpen(false)}
          >
            Ok Mengerti
          </Button>
        </div>
      </CustomModal>
    </Container>
  );
};

export default ComparePriceContent;
