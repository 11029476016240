import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Button, Col, FormGroup, Label, Row, Input } from 'reactstrap';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import Divider from '@components/divider/Divider';
import { Tooltip } from 'react-tooltip';
import InfoIcon from '@assets/icons/info.svg';
import { BOOKING_CATEGORY_OPTIONS, DISCOUNT_OPTIONS } from '@utils/Constants';
import API from '@utils/API';
import { find, map } from 'lodash';
import Helper from '@helpers/Helper';
import useSelectionRange from '@utils/useSelectionRange';

const BookingDetailCustomEditForms = (props) => {
  const {
    selectedData,
    modal,
    toggle,
    selectedWorkshop,
    detailOrder,
    disableAll,
    tab,
    handleChangeDetailOrder,
    markUpNDiscount,
    handlePriceChange,
  } = props;
  const api = new API('v2');

  const [selectedBookingOptions, setSelectedBookingOptions] = useState('product');
  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [errorCategory, setErrorCategory] = useState('');
  const [selectedUpRate, setSelectedUpRate] = useState({ value: 0, label: '0%' });
  const [selectedDiscount, setSelectedDiscount] = useState({ value: 0, label: '0%' });
  const [qty, setQty] = useState({
    formatIsMoney: false,
    formatMoney: 0,
    formatMoneyString: '0',
  });
  const [errorQty, setErrorQty] = useState('');
  const [nettPrice, setNettPrice] = useState({
    formatIsMoney: false,
    formatMoney: 0,
    formatMoneyString: 'IDR 0',
  });
  const [errorNettPrice, setErrorNettPrice] = useState('');
  const [price, setPrice] = useState({
    formatIsMoney: false,
    formatMoney: 0,
    formatMoneyString: 'IDR 0',
  });
  const [errorPrice, setErrorPrice] = useState('');
  const [takeRate, setTakeRate] = useState(0);
  const [isSplitedTax, setSplitedTax] = useState(false);

  let upRateOptions = [{ value: 0, label: '0%' }];
  let discountOptions = [{ value: 0, label: '0%' }];

  upRateOptions = [...upRateOptions, ...Helper.generatePercentage(1, 100)];
  discountOptions.push(DISCOUNT_OPTIONS);

  const getCategories = async () => {
    await api
      .get('v2/intools/products/categories/')
      .then(async (res) => {
        let dt = res?.data?.data?.categories;
        const transformedData = [];

        map(dt, (item) => {
          if (item.id !== '-') {
            transformedData.push({
              value: item.category_id ?? item.id,
              label: item.name,
            });
          }
        });
        setCategories(transformedData);
      })
      .catch((err) => console.log(err));
  };

  const handleChangeBookingOptions = (bookingOpt) => {
    setSelectedBookingOptions(bookingOpt.value);
  };

  const handleChangeName = (e) => {
    const { value } = e.target;
    setName(value.toUpperCase());
    if (value?.length < 1) {
      setErrorName('Nama harus diisi!');
    } else {
      setErrorName('');
    }
  };

  const handleChangeCategories = (value) => {
    setSelectedCategory(value);
    if (!value) {
      setErrorCategory('Kategori harus diisi!');
    } else {
      setErrorCategory('');
    }
  };

  const handleChangeQty = (e) => {
    const { value } = e.target;
    let quantity = 0;
    const qtyValue = value > 999 ? 999 : value;

    try {
      quantity = qtyValue;
    } catch {
      quantity = qtyValue;
    }

    setQty(quantity);
    if (value > 999) {
      setErrorQty('Maksimal jumlah yang dapat dipilih adalah 999');
    } else {
      setErrorQty('');
    }
  };

  const handleChangeNettPrice = (e) => {
    const { value } = e.target;
    const nettPriceValue =
      Helper.removeMoneyFormat(value) > 99999999999 ? 99999999999 : Helper.removeMoneyFormat(value);
    let nettprice = 0;

    try {
      nettprice = Helper.formatMoneyOnField(nettPriceValue, 'IDR ');
    } catch {
      nettprice = {
        formatIsMoney: false,
        formatMoney: nettPriceValue,
        formatMoneyString: `IDR ${Helper.formatMoney(nettPriceValue)}`,
      };
    }

    setNettPrice(nettprice);

    if (Helper.removeMoneyFormat(value) > 99999999999) {
      setErrorNettPrice(`Maksimal harga yang dapat ditentukan adalah IDR ${Helper.formatMoney(99999999999)}`);
    } else {
      setErrorNettPrice('');
    }
  };

  const handleChangePrice = (e) => {
    const { value } = e.target;
    const validatePriceValue =
      Helper.removeMoneyFormat(value) > 99999999999 ? 99999999999 : Helper.removeMoneyFormat(value);
    let priceValue = 0;

    try {
      priceValue = Helper.formatMoneyOnField(validatePriceValue);
    } catch {
      priceValue = {
        formatIsMoney: false,
        formatMoney: validatePriceValue,
        formatMoneyString: `IDR ${Helper.formatMoney(Math.trunc(validatePriceValue))}`,
      };
    }

    setPrice(priceValue);
    if (Helper.removeMoneyFormat(value) > 99999999999) {
      setErrorPrice(`Maksimal harga yang dapat ditentukan adalah IDR ${Helper.formatMoney(99999999999)}`);
    } else {
      setErrorPrice('');
    }
  };

  const handleChangeUpRate = (value) => {
    setSelectedUpRate(value);
    if (value?.value > 0) {
      const afterUpRate = Helper.countUpRate(value?.value, nettPrice.formatMoney, 1, false);

      const priceValue = {
        formatIsMoney: true,
        formatMoney: afterUpRate,
        formatMoneyString: `IDR ${Helper.numberWithCommas(Math.trunc(afterUpRate))}`,
      };

      setPrice(priceValue);
    }
  };

  const handleChangeDiscount = (value) => {
    setSelectedDiscount(value);
  };

  const handleChangeSplitedTaxCheckBox = () => setSplitedTax(!isSplitedTax);

  const generateId = () => {
    let prefix = `custom_${selectedBookingOptions}_`;
    return `${prefix}${detailOrder[selectedBookingOptions]?.length ?? 0}`;
  };

  const resetField = () => {
    setSelectedBookingOptions('product');
    setName('');
    setSelectedCategory();
    setSelectedUpRate({ value: 0, label: '0%' });
    setSelectedDiscount({ value: 0, label: '0%' });
    setQty({ formatIsMoney: false, formatMoney: 0, formatMoneyString: '0' });
    setNettPrice({
      formatIsMoney: false,
      formatMoney: 0,
      formatMoneyString: 'IDR 0',
    });
    setPrice({ formatIsMoney: false, formatMoney: 0, formatMoneyString: 'IDR 0' });
  };

  const validate = () => {
    let status = true;

    if (name?.length < 1) {
      setErrorName('Nama harus diisi!');
      status = false;
    }

    if (!selectedCategory) {
      setErrorCategory('Kategori harus diisi!');
      status = false;
    }

    if (qty.formatMoney < 1) {
      setErrorQty('Jumlah item harus diisi!');
      status = false;
    }

    if (qty.formatMoney > 999) {
      setErrorQty('Maksimal jumlah yang dapat dipilih adalah 999');
    }

    if (nettPrice.formatMoney > 99999999999) {
      setErrorNettPrice(`Maksimal harga yang dapat ditentukan adalah IDR ${Helper.formatMoney(99999999999)}`);
    }

    if (price.formatMoney > 99999999999) {
      setErrorPrice(`Maksimal harga yang dapat ditentukan adalah IDR ${Helper.formatMoney(99999999999)}`);
    }

    return status;
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    const payload = {
      name: {
        id: generateId(),
        label: name,
        price,
        nettPrice,
        priceBeforeTax: Helper.countDPP(nettPrice?.formatMoney),
        is_compatible: true,
      },
      type: selectedBookingOptions,
      formType: 'custom',
      qty,
      categories: selectedCategory,
      uprate: selectedUpRate,
      discount: selectedDiscount,
      takerate: takeRate,
      nettPrice: Helper.readNettPriceCustom(takeRate, nettPrice?.formatMoney),
      isSplitedTax,
    };

    handleChangeDetailOrder(payload);
    handlePriceChange(
      selectedWorkshop?.id,
      selectedData?.name?.id,
      price?.formatMoney,
      nettPrice?.formatMoney,
      selectedData?.name?.price?.formatMoney,
      selectedData?.name?.nettPrice?.formatMoney,
      isSplitedTax,
      'allPrice'
    );
    toggle();
    resetField();
  };

  const cancel = () => {
    toggle();
  };

  const setDefaultData = () => {
    if (!selectedData?.name?.price?.formatMoneyString) {
      selectedData.name.price.formatMoneyString = `IDR ${new Intl.NumberFormat('id-ID', {
        style: 'decimal',
        maximumFractionDigits: 0,
      }).format(selectedData.name.price?.formatMoney || 0)}`;
    }

    setSelectedCategory(selectedData?.categories ?? null);
    setSelectedBookingOptions(selectedData?.type ?? '');
    setName(selectedData?.name?.label ?? '');
    setQty(selectedData?.qty);
    setNettPrice(selectedData?.name?.nettPrice);
    setPrice(selectedData?.name?.price);
    setSelectedUpRate(selectedData?.uprate);
    setSelectedDiscount(selectedData?.discount);
    setSplitedTax(selectedData?.isSplitedTax);
  };

  const checkPKPWS = () => {
    if (isSplitedTax) !selectedWorkshop?.is_pkp ? setSplitedTax(false) : null;
  };

  useEffect(() => {
    getCategories('product');
  }, []);

  useEffect(() => {
    selectedWorkshop?.product_take_rate || selectedWorkshop?.service_take_rate
      ? selectedBookingOptions === 'product'
        ? setTakeRate(selectedWorkshop?.product_take_rate)
        : setTakeRate(selectedWorkshop?.service_take_rate)
      : setTakeRate(0);
  }, [selectedWorkshop]);

  useEffect(() => {
    const discount = selectedBookingOptions === 'product' ? markUpNDiscount?.discProduct : markUpNDiscount?.discService;
    const markup =
      selectedBookingOptions === 'product' ? markUpNDiscount?.markUpProduct : markUpNDiscount?.markUpService;
    setSelectedDiscount({ value: discount, label: `${discount}%` });
    setSelectedUpRate({ value: markup, label: `${markup}%` });
  }, [markUpNDiscount, selectedBookingOptions]);

  useEffect(() => {
    if (selectedWorkshop?.product_take_rate || selectedWorkshop?.service_take_rate) {
      setTakeRate(
        selectedBookingOptions === 'product' ? selectedWorkshop?.product_take_rate : selectedWorkshop?.service_take_rate
      );
    }
  }, [selectedBookingOptions]);

  useEffect(() => {
    if (modal) {
      setDefaultData();
    }
  }, [modal, categories]);

  const bookingType = find(BOOKING_CATEGORY_OPTIONS, { value: selectedBookingOptions });

  return (
    <Modal
      className="modal-dialog-centered modal-form"
      isOpen={modal}
      toggle={toggle}
      onOpened={checkPKPWS}
      backdrop="static"
    >
      <ModalBody className="body mt-3">
        <div className="header">Edit Custom</div>
        <div className="content">
          <FormGroup className="d-flex">
            <Label lg={3} className="label-required">
              Tipe Flagging
            </Label>
            <Col lg={9} className="px-2">
              <div className="mb-1">
                {map(BOOKING_CATEGORY_OPTIONS, (bookingOpt, index) => (
                  <Button
                    id={`custom-${bookingOpt?.value}-edit`}
                    className={index > 0 ? 'ml-2' : ''}
                    color="purple"
                    outline={selectedBookingOptions !== bookingOpt.value}
                    onClick={() => handleChangeBookingOptions(bookingOpt)}
                    disabled={disableAll}
                  >
                    {bookingOpt.label}
                  </Button>
                ))}
              </div>
              <span className="text-danger" style={{ fontSize: 11.28 }}></span>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label lg={3} className="label-required">{`Nama ${bookingType.label}`}</Label>
            <InputField
              id={`${bookingType?.value}-name-edit`}
              colWidth={9}
              onChange={async (e) => {
                const { start, end, inputElm } = useSelectionRange(e);

                await handleChangeName(e);
                inputElm.setSelectionRange(start, end);
              }}
              value={name}
              placeholder="Ex: Oli Motul 4T 5100 10W-40"
              disabled={disableAll}
              invalid={!!errorName}
              errorMessage={errorName}
            />
          </FormGroup>

          <FormGroup row>
            <Label lg={3} className="label-required">
              Kategori
            </Label>
            <SelectField
              id="custom-category-edit"
              colWidth={9}
              options={categories}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              onChange={handleChangeCategories}
              value={selectedCategory || ''}
              invalid={!selectedCategory}
              errorMessage={errorCategory}
              placeholder="Pilih Kategori..."
              disabled={disableAll}
            />
          </FormGroup>

          <FormGroup row>
            <Label lg={3} className="label-required">
              Jumlah
            </Label>
            <InputField
              inputType="number"
              id="custom-qty-edit"
              colWidth={9}
              onChange={handleChangeQty}
              value={qty?.toString()}
              disabled={disableAll}
              invalid={!!errorQty}
              errorMessage={errorQty}
            />
          </FormGroup>

          <Divider />

          <FormGroup row>
            <Tooltip id="info-split-tax" />
            <Label lg={3}>
              <img
                src={InfoIcon}
                alt=""
                className="mr-1 cursor-pointer"
                width={16}
                data-tooltip-id="info-split-tax"
                data-tooltip-content="Jika diaktifkan, maka operator mengisi Harga Jual Bengkel termasuk PPN lalu sistem akan menghitung DPP"
              />
              Split Tax Pricing
            </Label>
            <Col lg={9} style={{ padding: '6px 12px', display: 'flex', alignItems: 'center' }}>
              <Input
                id="split-tax-check-edit"
                type="checkbox"
                onChange={handleChangeSplitedTaxCheckBox}
                checked={isSplitedTax}
                disabled={disableAll || !!!selectedWorkshop?.is_pkp}
              />
            </Col>
          </FormGroup>

          {isSplitedTax ? (
            <FormGroup row>
              <Label lg={3}>DPP Jual Bengkel</Label>
              <Col lg={9} sm={12} className="d-flex align-items-center">
                <span>{`IDR ${Helper.formatMoney(Helper.countDPP(nettPrice?.formatMoney))}`}</span>
              </Col>
            </FormGroup>
          ) : null}

          <FormGroup row>
            <Label lg={3}>{isSplitedTax ? 'Harga Jual Bengkel Termasuk PPN' : 'DPP Jual Bengkel'}</Label>
            <InputField
              id={isSplitedTax ? 'include-ppn-workshop-price-edit' : 'dpp-workshop-price-edit'}
              colWidth={9}
              onChange={handleChangeNettPrice}
              value={nettPrice.formatMoneyString}
              disabled={disableAll}
              invalid={!!errorNettPrice}
              errorMessage={errorNettPrice}
            />
          </FormGroup>

          <FormGroup row>
            <Label lg={3}>Take Rate</Label>
            <Col lg={9} className="d-flex align-items-center">
              <span>{`${takeRate}%`}</span>
            </Col>
          </FormGroup>

          <Divider />

          <FormGroup row>
            <Label lg={3}>DPP Jual Customer</Label>
            <InputField
              id="dpp-customer-price"
              colWidth={9}
              onChange={handleChangePrice}
              value={price.formatMoneyString}
              disabled={disableAll}
              invalid={!!errorPrice}
              errorMessage={errorPrice}
            />
          </FormGroup>

          <FormGroup row>
            <Label lg={3}>Mark Up</Label>
            <Col lg={9} sm={12} className="d-flex align-items-center">
              <span>{selectedUpRate?.label}</span>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label lg={3}>Diskon</Label>
            <Col lg={9} sm={12} className="d-flex align-items-center">
              <span>{selectedDiscount?.label}</span>
            </Col>
          </FormGroup>

          <div className="action mb-3 mt-3">
            <Button id="custom-edit-cancel" className="button-cancel" onClick={cancel} disabled={disableAll}>
              Batal
            </Button>
            <Button id="custom-edit-save" className="button-action" onClick={handleSubmit} disabled={disableAll}>
              Simpan
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BookingDetailCustomEditForms;
