import React, { useEffect, useState } from 'react';
import { ComponentProps, Streamlit, withStreamlitConnection } from 'streamlit-component-lib';
import { useDispatch, useSelector } from 'react-redux';
import { hasLoggedIn, getUsername } from '../../reducers/Auth';
import { Dictionary } from '@reduxjs/toolkit';

/**
 * No more props manipulation in the code.
 * We store props in state and pass value directly to underlying Slider
 * and then back to Streamlit.
 */

interface PythonArgs {
  label: string;
}
const StreamlitLogin = (props: ComponentProps) => {
  const { label }: PythonArgs = props.args;
  const isLoggedIn = useSelector(hasLoggedIn);
  const username = useSelector(getUsername);

  const sendValueToStreamlit = () => {
    const valueToSend = {
      username: username || '', // Fallback to empty string if undefined
      hasLoggedIn: isLoggedIn,
    };

    // Send the value back to Streamlit using setComponentValue
    Streamlit.setComponentValue(valueToSend);
  };

  useEffect(() => {
    sendValueToStreamlit();
    Streamlit.setComponentReady();
  }, [isLoggedIn]);

  useEffect(() => {
    Streamlit.setFrameHeight();
  }, []);

  return <></>;
};

export default withStreamlitConnection(StreamlitLogin);
