import { map } from 'lodash';
import React from 'react';
import { Col } from 'reactstrap';
import BookingCreateDetailOrderCard from './BookingCreateDetailOrderCard';

const BookingCreateDetailOrder = (props) => {
  const {
    data,
    selectedWorkshop,
    selectedCar,
    selectedUser,
    accountType,
    isECommerce,
    disableAll,
    handleEditDetailOrder,
    handleDeleteDetailOrder,
    handleResultNotification,
    markUpNDiscount,
    calculatePromo,
    promoPackageStatusEdit,
    handleChangePromoPackageStatusEdit,
    selectedWorkshops,
    handlePriceChange,
  } = props;

  const hasNoItems = data?.package?.length < 1 && data?.product?.length < 1 && data?.service?.length < 1;

  return (
    <Col lg={9} className="d-flex flex-column">
      <span style={{ fontSize: 14, fontWeight: 700 }}>Hasil Pemesanan</span>
      {hasNoItems ? (
        <div>
          <span className="text-muted">Tambahkan Paket/Produk/Jasa untuk melihat Hasil Pemesanan</span>
        </div>
      ) : null}
      {data?.package?.length > 0
        ? map(data.package, (item, index) => (
            <BookingCreateDetailOrderCard
              handlePriceChange={handlePriceChange}
              selectedWorkshops={selectedWorkshops}
              key={index}
              index={index}
              orderType="package"
              title={item?.name?.label}
              items={item}
              selectedWorkshop={selectedWorkshop}
              selectedCar={selectedCar}
              selectedUser={selectedUser}
              accountType={accountType}
              isECommerce={isECommerce}
              packageWorkshopId={item?.name?.workshop_id || item?.workshop_id}
              disableAll={disableAll}
              onEdit={handleEditDetailOrder}
              onDelete={handleDeleteDetailOrder}
              markUpNDiscount={markUpNDiscount}
              calculatePromo={calculatePromo}
              promoPackageStatusEdit={promoPackageStatusEdit}
              handleChangePromoPackageStatusEdit={handleChangePromoPackageStatusEdit}
            />
          ))
        : null}

      {data?.product?.length > 0 ? <span style={{ fontSize: 14, fontWeight: 700 }}>Produk</span> : null}
      {data?.product?.length > 0
        ? map(data.product, (item, index) => (
            <BookingCreateDetailOrderCard
              handlePriceChange={handlePriceChange}
              selectedWorkshops={selectedWorkshops}
              detailOrder={data}
              key={index}
              index={index}
              orderType="product"
              items={item}
              quantity={item?.qty}
              selectedWorkshop={selectedWorkshop}
              selectedCar={selectedCar}
              selectedUser={selectedUser}
              accountType={accountType}
              isECommerce={isECommerce}
              disableAll={disableAll}
              onEdit={handleEditDetailOrder}
              onDelete={handleDeleteDetailOrder}
              calculatePromo={calculatePromo}
              promoPackageStatusEdit={promoPackageStatusEdit}
              handleChangePromoPackageStatusEdit={handleChangePromoPackageStatusEdit}
              handleResultNotification={handleResultNotification}
              markUpNDiscount={markUpNDiscount}
            />
          ))
        : null}

      {data?.service?.length > 0 ? <span style={{ fontSize: 14, fontWeight: 700 }}>Jasa</span> : null}
      {data?.service?.length > 0
        ? map(data.service, (item, index) => (
            <BookingCreateDetailOrderCard
              handlePriceChange={handlePriceChange}
              selectedWorkshops={selectedWorkshops}
              detailOrder={data}
              key={index}
              index={index}
              orderType="service"
              items={item}
              quantity={item?.qty}
              selectedWorkshop={selectedWorkshop}
              selectedCar={selectedCar}
              selectedUser={selectedUser}
              accountType={accountType}
              isECommerce={isECommerce}
              disableAll={disableAll}
              onEdit={handleEditDetailOrder}
              onDelete={handleDeleteDetailOrder}
              promoPackageStatusEdit={promoPackageStatusEdit}
              handleChangePromoPackageStatusEdit={handleChangePromoPackageStatusEdit}
              handleResultNotification={handleResultNotification}
              markUpNDiscount={markUpNDiscount}
              calculatePromo={calculatePromo}
            />
          ))
        : null}
    </Col>
  );
};

export default BookingCreateDetailOrder;
